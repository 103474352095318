import { OfferResponse, OfferResumeResponse } from '@asgard/api-common';
import { UIOfferBadge } from 'interfaces/ui/ui-offer-badge';
import { UIOfferPerk } from 'interfaces/ui/ui-offer-perk';
import { UIOfferSkill } from 'interfaces/ui/ui-offer-skill';
import {
  UIOfferRanges,
  UIOfferWhenSchedule
} from 'interfaces/ui/ui-offer-when-schedule';
import { WebOffer } from 'model/model.web-offer';
import { WebOfferSummary } from 'model/model.web-offer-summary';
import { i18n } from 'next-i18next';
import { stripHtml, stripMarkdown } from 'utils/cleanup';
import { getTranslation } from 'utils/i18n';
import { LOGO_MANFRED, LOGO_MANFRED_WHITE } from 'utils/logos';
import { getPublicPath } from 'utils/paths';
import { getMinutesFromStringTime, getStringTimeFromMinutes } from 'utils/time';
import {
  OFFER_SOFT_SKILLS_SECTION,
  OFFER_TECH_SKILLS_LEVEL,
  OFFER_TECH_SKILLS_SECTION
} from './offers.service';
import { CommonDtoLevelToLanguageLevelDict } from './service.language.normalizer';
import { UILocationsSummary } from './ui/offer.service';
import { getSalaryRangeText } from './ui/salary.service';

export function normalizeToWebOfferSummaryList(
  res: OfferResumeResponse[],
  locale: string
): WebOfferSummary[] {
  return res.map((offer) => normalizeToWebOfferSummary(offer, locale));
}

export function normalizeToWebOfferSummary(
  offer: OfferResumeResponse,
  locale: string
): WebOfferSummary {
  const badges = getOfferBadges(offer, locale).filter(
    (o) => o.item !== null && o.item !== ''
  );

  const summary: WebOfferSummary = {
    slug: offer.slug,
    id: offer.id,
    title: offer.position,

    badges,
    bonus: offer.bonus
      ? getKSalary(offer.bonus)?.toLocaleString(locale)
      : undefined,
    equityInf: offer.equityInf ? `${offer.equityInf}` : null,
    equitySup: offer.equitySup ? `${offer.equitySup}` : null,

    company: offer.company.name,
    companyUrl: offer.company.web,
    companyColor: offer.company.primaryColor,
    companyPhoto: offer.company.photo?.url,
    companyLogo: offer.company.logo?.url,

    isFreelance: offer.isFreelance,
    hourlyRateFrom: offer.feeRateFrom,
    hourlyRateTo: offer.feeRateTo,

    currency: offer.currency,
    salaryMin: getKSalary(offer.salaryFrom),
    salaryMax: getKSalary(offer.salaryTo),
    locationsSummary: getSummaryFromLocations(offer.locations),
    remote: offer.remotePercentage,
    active: offer.status === 'ACTIVE',
    url: `/${offer.id}/${offer.slug}`,
    internalCode: offer.internalCode,
    isOneLanguage:
      offer.offerLanguages.length === 1
        ? offer.offerLanguages[0].toLowerCase() !== locale.toLowerCase()
        : false,
    updatedAt: new Date(offer.updatedAt)
  };

  return summary;
}

export function normalizeToWebOffer(
  res: OfferResponse,
  locale: string
): WebOffer {
  const badges = getOfferBadges(res, locale, true);
  const summary: WebOfferSummary = normalizeToWebOfferSummary(res, locale);
  const migratedToMarkdown = res.migratedToMarkdown;

  const offer: WebOffer = {
    ...summary,

    migratedToMarkdown,

    isFreelance: res.isFreelance,
    hourlyRateFrom: res.feeRateFrom,
    hourlyRateTo: res.feeRateTo,

    jsonld: normalizeToJSONLD(res),
    languages: res.offerLanguages.map((lang) => ({
      name: lang,
      code: lang
    })),

    status: res.status,
    footerData: {
      id: res.id,
      logo: res.company.logoDark?.url,
      company: res.company.name,
      scout: `${res.scout?.firstName} ${res.scout?.lastName}`,
      scoutAvatar: res.scout?.avatar?.url,
      scoutUrl: res.scout?.email || '',
      typeFormURL: '', // Deprecated
      closed: res.status === 'CLOSED',
      internalCode: res.internalCode,
      isFreelance: res.isFreelance
    },

    heroSectionData: {
      migratedToMarkdown,
      companyName: res.company.name,
      companyLogo: res.company.logo?.url,
      companyLogoDark: res.company.logoDark?.url ?? res.company.logo?.url,
      companyUrl: res.company.web,
      companyArea: 'Área de desarrollo y Seguridad',
      companyPrimaryColor: res.company.primaryColor,
      companySecondaryColor: res.company.secondaryColor,
      companyPhoto:
        res.company.photo?.url ??
        getPublicPath('/images/jobOffer/companyPhoto.jpg'),
      companyPhotoAlt: res.company.photo?.alt ?? res.company.name,
      companyManagementLogo: LOGO_MANFRED,
      companyManagementLogoAlt: 'Manfred',
      companyScout: `${res.scout?.firstName} ${res.scout?.lastName}`,
      companyScoutUrl: `${res.scout?.email}`,
      companyScoutAvatar: res.scout?.avatar?.url,
      jobTitle: res.position,
      salaryMin: getKSalary(res.salaryFrom),
      salaryMax: getKSalary(res.salaryTo),
      isFreelance: res.isFreelance,
      hourlyRateFrom: res.feeRateFrom,
      hourlyRateTo: res.feeRateTo,
      currency: res.currency,
      jobLocationsSummary: getSummaryFromLocations(res.locations),
      jobRemote: res.remotePercentage,
      jobLocationCombined:
        res.remotePercentage !== null && res.remotePercentage !== 0,
      badges,
      jobDescription: res.introduction,
      companyManagementLogoDark: LOGO_MANFRED_WHITE,
      closed: res.status === 'CLOSED',
      closedDate: res.lastStatusChange,
      bonus: res.bonus
        ? `${getKSalary(res.bonus)?.toLocaleString(locale)}`
        : undefined
    },

    requirementsSectionData: {
      migratedToMarkdown,
      companyLogo: res.company.logo?.url,
      companyName: res.company.name,
      isFreelance: res.isFreelance,
      evolution: {
        month1: res.inOneMonth,
        month3: res.inThreeMonths,
        month6: res.inSixMonths
      },
      requirementsIntro: res.whatWillYouDo,
      responsabilities: res.responsibilities.map((resp, index) => ({
        id: `web-offer-responsibility_${index}`,
        item: resp
      }))
    },

    howSectionData: {
      howIntro: res.howWillYouDoIt || '',
      howItems: [],
      migratedToMarkdown,
      isFreelance: res.isFreelance
    },

    whenSectionData: {
      migratedToMarkdown,
      whenIntro: res.whenWillDoIt,
      highlights: getJobScheduleHighlights(res, locale),
      schedules: getJobSchedules(res),
      isFreelance: res.isFreelance,
      watchIntro: res.watches?.description,
      watchFaqs: (res.watches?.faq ?? []).map((w) => ({
        a: w.answer,
        q: w.question
      }))
    },

    whereSectionData: {
      migratedToMarkdown,
      jobLocationsSummary: getSummaryFromLocations(res.locations),
      jobRemote: res.remotePercentage,
      jobInfo: res.whereWillDoIt || '',
      companyLogo: res.company.logo?.url,

      // Deprecated
      companyAddress: '',
      googleMaps: '',
      spacesSlides: [],
      officePerks: [],
      isFreelance: res.isFreelance
    },

    teamSectionData: {
      migratedToMarkdown,
      teamInfo: res.whoWillDoItWith || '',

      // Deprecated
      teamImage: '',
      teamImageCaption: '',
      membersInfo: '',
      members: [],
      isFreelance: res.isFreelance
    },

    skillsSectionData: {
      migratedToMarkdown,
      intro: res.whatTheyAskFor,
      noStack: res.noStack,
      minLanguages:
        res.languages
          ?.map((minLang) => ({
            name: minLang.name,
            level:
              CommonDtoLevelToLanguageLevelDict[minLang.level || 'Undefined']
          }))
          .filter((minLang) => minLang.level !== 'not-defined') || [],

      skills: {
        must: getOfferTechsBySection(res, OFFER_TECH_SKILLS_SECTION.MUST),
        nice: getOfferTechsBySection(res, OFFER_TECH_SKILLS_SECTION.COULD),
        extra: getOfferTechsBySection(res, OFFER_TECH_SKILLS_SECTION.EXTRA)
      },
      softSkills: {
        must: getOfferSoftSkillsBySection(res, OFFER_SOFT_SKILLS_SECTION.MUST),
        nice: getOfferSoftSkillsBySection(res, OFFER_SOFT_SKILLS_SECTION.COULD),
        extra: getOfferSoftSkillsBySection(res, OFFER_SOFT_SKILLS_SECTION.EXTRA)
      },
      isFreelance: res.isFreelance
    },

    offerSectionData: {
      migratedToMarkdown,
      companyName: res.company.name || '',
      offerCode: res.internalCode,
      offer: {
        salaryMin: getKSalary(res.salaryFrom),
        salaryMax: getKSalary(res.salaryTo),
        isFreelance: res.isFreelance,
        hourlyRateFrom: res.feeRateFrom,
        hourlyRateTo: res.feeRateTo,
        currency: res.currency,
        salaryExtra: getEquityInfo(res, locale),
        bonus: res.bonus
          ? `${getKSalary(res.bonus)?.toLocaleString(locale)}`
          : undefined,
        bonusInfo: 'res.bonusInfo',
        contract: '',
        hours: res.workingDayInfo?.hasFlexTime || false,
        remote: res.remotePercentage ? `${res.remotePercentage}%` : undefined,
        equityInf: res.equityInf ? res.equityInf.toLocaleString(locale) : null,
        equitySup: res.equitySup ? res.equitySup.toLocaleString(locale) : null
      },
      offerInfo: res.whatOffering || '',
      perks: {
        compensation: [
          {
            perk: 'Cheques restaurant',
            icon: getPublicPath('/images/icons/restaurant-voucher.svg')
          },
          {
            perk: 'Transporte',
            icon: getPublicPath('/images/icons/bus.svg'),
            desc: 'Bono transporte'
          }
        ],
        extra: getOfferPerks(res)
      },
      isFreelance: res.isFreelance
    },

    faqsSectionData: {
      migratedToMarkdown,
      faqs: res.faq.map((f) => ({
        a: f.answer,
        q: f.question
      })),
      isFreelance: res.isFreelance
    },

    bottombarData: {
      migratedToMarkdown,
      id: res.id,
      companyName: res.company.name,
      companyLogo: res.company.logoDark?.url ?? res.company.logo?.url,
      typeFormUrl: '', // Deprecated
      jobTitle: res.position,
      salaryMin: getKSalary(res.salaryFrom),
      salaryMax: getKSalary(res.salaryTo),
      isFreelance: res.isFreelance,
      hourlyRateFrom: res.feeRateFrom,
      hourlyRateTo: res.feeRateTo,
      currency: res.currency,
      jobLocationsSummary: getSummaryFromLocations(res.locations),
      jobRemote: res.remotePercentage,
      jobLocationCombined:
        res.remotePercentage !== null && res.remotePercentage !== 0,
      hasEquity: Boolean(res.equityInf) || Boolean(res.equitySup),
      hasBonus: Boolean(res.bonus),
      closed: res.status === 'CLOSED',
      equityInf: res.equityInf ? res.equityInf?.toLocaleString(locale) : null,
      equitySup: res.equitySup ? res.equitySup?.toLocaleString(locale) : null,
      bonus: res.bonus
        ? `${getKSalary(res.bonus)?.toLocaleString(locale)}`
        : null,
      internalCode: res.internalCode
    }
  };

  return offer;
}

function getKSalary(salary: number): number {
  if (salary < 1000) return salary;

  return salary / 1000;
}

function getSummaryFromLocations(locations: string[]): UILocationsSummary {
  const MAX_CITIES = 4;

  const allLocations =
    locations
      .map((location) =>
        location.replace(', España', '').replace(', Spain', '')
      )
      .filter(Boolean) || [];

  const allLocationsCount = allLocations.length;

  const trimmedCities = allLocations.slice(0, MAX_CITIES);
  const trimmedCount = trimmedCities.length;
  const trimmedCitiesStr =
    trimmedCities.join(` ${i18n?.t('or', { ns: 'common' }) || ', '} `) || '';

  const extraCities = allLocations.slice(MAX_CITIES, allLocationsCount - 1);
  const extraCount = extraCities.length;
  const extraCitiesStr =
    extraCities.join(` ${i18n?.t('or', { ns: 'common' }) || ', '} `) || '';

  return {
    trimmedCount,
    trimmedCities,
    trimmedCitiesStr,
    extraCount,
    extraCities,
    extraCitiesStr
  };
}

function getOfferBadges(
  offer: OfferResumeResponse,
  locale: string = 'es',
  addEquity?: boolean
): UIOfferBadge[] {
  const badges: UIOfferBadge[] = offer.noStack
    ? [{ id: 'badge1', item: 'No-stack', highlight: true }]
    : [];

  if (offer.isFreelance) {
    badges.push({ id: 'badgefreelance', item: 'Freelance', highlight: true });
  }

  const equityInf = offer.equityInf;
  const equitySup = offer.equitySup;

  if (addEquity && (equityInf || equitySup)) {
    let text = `${equityInf?.toLocaleString(
      locale
    )}% - ${equitySup?.toLocaleString(locale)}%`;

    if (equityInf === equitySup) {
      text = `${equitySup?.toLocaleString(locale)}%`;
    }

    if (equityInf && !equitySup) {
      text = `${i18n
        ?.t('hero.from', { ns: 'job-offer', lng: locale })
        .toLowerCase()} ${equityInf?.toLocaleString(locale)}%`;
    }

    if (!equityInf && equitySup) {
      text = `${i18n
        ?.t('hero.up_to', { ns: 'job-offer' })
        .toLowerCase()} ${equitySup?.toLocaleString(locale)}%`;
    }

    badges.push({
      id: 'badgeequity',
      item: `${getTranslation('offer.equity', 'job-offer', locale)} ${text}`
    });
  }

  return [
    ...badges,
    ...(offer.highlights || [])
      .filter((badge) =>
        offer.offerLanguages.length > 1 ? locale && !!badge : true
      )
      .map((h, i) => {
        return {
          id: `${i}`,
          item: h,
          highlight: false
        };
      })
  ];
}

function getJobScheduleHighlights(
  offer: OfferResponse,
  locale?: string
): {
  title: string;
  value: string;
  icon: string;
  tooltip?: string;
}[] {
  const highlights: {
    title: string;
    value: string;
    icon: string;
    tooltip?: string;
  }[] = [];

  if (offer.workingDayInfo?.isFullTime) {
    highlights.push({
      title: getTranslation('when.highlights.workday', 'job-offer', locale),
      value: getTranslation(
        'when.highlights.workdayFulltime',
        'job-offer',
        locale
      ),
      icon: getPublicPath('/images/icons/calendar.svg')
    });
  }

  highlights.push({
    title: getTranslation('when.highlights.holidays', 'job-offer', locale),
    value:
      offer.workingDayInfo?.annualHolidays === 0
        ? getTranslation('when.highlights.unlimited', 'job-offer', locale)
        : getTranslation('when.highlights.days', 'job-offer', locale, {
            days: offer.workingDayInfo?.annualHolidays || 22
          }),
    icon: getPublicPath('/images/icons/relax.svg')
  });

  if (offer.workingDayInfo?.hasFlexTime) {
    highlights.push({
      title: getTranslation('when.highlights.jobSchedule', 'job-offer', locale),
      value: getTranslation('when.highlights.flexible', 'job-offer', locale),
      icon: getPublicPath('/images/icons/clock.svg')
    });
  }

  if (offer.workingDayInfo?.hasContinuousWorkDayOnFridays) {
    highlights.push({
      title: getTranslation(
        'when.highlights.continuousWorkday',
        'job-offer',
        locale
      ),
      value: getTranslation('when.highlights.onFriday', 'job-offer', locale),
      icon: getPublicPath('/images/icons/important-day.svg')
    });
  }

  if (offer.workingDayInfo?.hasContinuousWorkDayInSummer) {
    highlights.push({
      title: getTranslation(
        'when.highlights.continuousWorkday',
        'job-offer',
        locale
      ),
      value: getTranslation('when.highlights.onSummer', 'job-offer', locale),
      icon: getPublicPath('/images/icons/holidays.svg')
    });
  }

  return highlights;
}

function getJobSchedules(offer: OfferResponse): UIOfferWhenSchedule[] {
  return (
    offer.workingDayInfo?.schedules?.map(({ name, text, bars }) => {
      return {
        name: name,
        text: text,
        bars: bars?.map(({ inOutFlexibleMinutes, ranges, title, subtitle }) => {
          const allRanges: UIOfferRanges[] = [];

          if (inOutFlexibleMinutes > 0) {
            allRanges.push({
              startTime: '',
              endTime: '',
              totalTime: +(inOutFlexibleMinutes / 60).toFixed(2),
              flexibleTime: true,
              freeTime: false
            });
          }

          allRanges.push(
            ...(ranges || []).map(({ startTime, endTime, isFreeTime }) => {
              return {
                startTime,
                endTime,
                totalTime: +(
                  (getMinutesFromStringTime(endTime) -
                    getMinutesFromStringTime(startTime)) /
                  60
                ).toFixed(2),
                flexibleTime: false,
                freeTime: isFreeTime
              };
            })
          );

          if (inOutFlexibleMinutes > 0) {
            const previousRangeEndTimeInMinutes = getMinutesFromStringTime(
              allRanges[allRanges.length - 1].endTime
            );

            const firstRangeStartTimeInMinutes = getMinutesFromStringTime(
              allRanges[1].startTime
            );

            allRanges[0].startTime = getStringTimeFromMinutes(
              firstRangeStartTimeInMinutes - inOutFlexibleMinutes
            );

            allRanges[0].endTime = allRanges[1].startTime;

            allRanges.push({
              startTime: allRanges[allRanges.length - 1].endTime,
              endTime: getStringTimeFromMinutes(
                previousRangeEndTimeInMinutes + inOutFlexibleMinutes
              ),
              totalTime: +(inOutFlexibleMinutes / 60).toFixed(2),
              flexibleTime: true,
              freeTime: false
            });
          }

          return {
            title,
            subtitle,
            inOutFlexibleMinutes,
            caption: '',
            ranges: allRanges
          };
        })
      };
    }) || []
  );
}

function getOfferTechsBySection(
  offer: OfferResponse,
  section: OFFER_TECH_SKILLS_SECTION
): UIOfferSkill[] {
  if (!offer.techs) {
    return [];
  }

  return offer.techs
    .filter((t) => t.section === section)
    .sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1))
    .map((t) => {
      return {
        skill: t.name,
        icon: t.icon?.url || '',
        level: UITechSkillsLevel[t.level],
        desc: ''
      };
    });
}

const UITechSkillsLevel: { [key in OFFER_TECH_SKILLS_LEVEL]: number } = {
  ADVANCED: 3,
  INTERMEDIATE: 2,
  BASIC: 1
};

function getOfferSoftSkillsBySection(
  offer: OfferResponse,
  section: OFFER_SOFT_SKILLS_SECTION
): UIOfferSkill[] {
  if (!offer.softSkills) {
    return [];
  }

  return offer.softSkills
    .filter((t) => t.section === section)
    .sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1))
    .map((t) => {
      return {
        skill: t.name,
        icon: t.icon?.url || '',
        desc: t.description
      };
    });
}

function getEquityInfo(offer: OfferResponse, locale?: string): string {
  const equity = [
    offer.equityInf?.toLocaleString(locale),
    offer.equitySup?.toLocaleString(locale)
  ]
    .filter(Boolean)
    .join('-');

  return equity ? equity : '';
}

function getOfferPerks(offer: OfferResponse): UIOfferPerk[] | void[] {
  if (!offer.perks) {
    return [];
  }

  return offer.perks?.map((perk) => ({
    perk: perk.name,
    icon: perk.icon?.url || '',
    desc: perk.description
  }));
}

const normalizeToJSONLD = (offer: OfferResponse): string => {
  const jobLocation = getOfferLocation(offer);

  const ldData = {
    '@context': 'https://schema.org',
    '@type': 'JobPosting',
    datePosted: offer.lastStatusChange,
    description:
      offer.introduction &&
      (offer.migratedToMarkdown
        ? stripMarkdown(offer.introduction)
        : stripHtml(offer.introduction)),

    hiringOrganization: {
      '@type': 'Organization',
      name: offer.company?.name,
      sameAs: offer.company?.web,
      logo: offer.company?.logo?.url
    },
    title: offer.position,

    ...jobLocation,

    validThrough:
      offer.status === 'CLOSED' ? offer.lastStatusChange : undefined,
    employmentType:
      !offer.isFreelance && offer.workingDayInfo?.isFullTime
        ? 'FULL_TIME'
        : 'OTHER',
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: offer.currency,
      value: {
        '@type': 'QuantitativeValue',
        minValue: !offer.isFreelance ? offer.salaryFrom : undefined,
        maxValue: !offer.isFreelance ? offer.salaryTo : undefined,
        value: !offer.isFreelance
          ? getSalaryRangeText({
              id: 0,
              min: offer.salaryFrom,
              max: offer.salaryTo,
              currency: offer.currency
            })
          : offer.feeRateFrom || 0,
        unitText: offer.isFreelance ? 'HOUR' : 'YEAR'
      }
    }
  };

  return JSON.stringify(ldData);
};

function getOfferLocation(offer: OfferResponse): {
  jobLocation?: {
    '@type': 'Place';
    address: {
      '@type': 'PostalAddress';
      addressLocality: string;
    };
  };
  jobLocationType?: string;
  applicantLocationRequirements?: {
    '@type': 'Country';
    name: 'Spain';
  };
} {
  return {
    jobLocation:
      offer.locations && offer.locations.length > 0
        ? {
            '@type': 'Place',
            address: {
              '@type': 'PostalAddress',
              addressLocality: getSummaryFromLocations(offer.locations)
                .extraCitiesStr
            }
          }
        : undefined,
    jobLocationType: offer.remotePercentage ? 'TELECOMMUTE' : undefined,
    applicantLocationRequirements:
      offer.remotePercentage === 100
        ? {
            '@type': 'Country',
            name: 'Spain'
          }
        : undefined
  };
}
